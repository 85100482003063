@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto";
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #454545;
}

.main-container {
    margin-top: 0;
    border: 3px solid black;
    box-shadow: -1px 1px 15px -10px black;
    padding: 3rem;
    padding-bottom: 5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.main-container h1 {
    color: black;
}

h1 {
    color: white;
    font-size: 2.5rem;
    padding: 3rem 0;
}

.main-container .filter {
    display: flex;
    gap: 5rem;
}
